import styled from "styled-components";
import { ListStyles } from "./List.types";
import { Breakpoints } from "../AppTheme";

export const StyledList = styled.ul`
  overflow-y: auto;
  box-sizing: border-box;

  /*
    Creates gutter around list to prevent focus ring overlap, provide visual contrast
    Margin prevents ring from drawing under filter and map,
    + maintains its visibility on bottom/left edges of browser
    Border prevents ring from drawing under LocationCards or scrollbar
  */
  margin: 2px 2px 3px 3px;
  border: 2px solid #fff;

  //Creates new stacking context s.t. LocCard buttons don't render on top of filter dropdown
  isolation: isolate;

  //removes bullets
  list-style-type: none;

  #load-all-btn {
    width: fit-content;
  }

  #listFooter {
    width: inherit;
    box-sizing: border-box;
    padding: var(--lib-default-spacing);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media ${Breakpoints.mobile} {
    display: ${({ isMapView }: ListStyles) => (isMapView ? "none" : "")};
    margin: 2px; //no longer as necessary in single col layout

    //Horizontally align Load All btn with LocCard padding to avoid overlap with FAB
    #load-all-btn {
      margin: var(--lib-default-spacing);
    }
  }
`;

export const StyledSkeletonCard = styled.li`
  //identical to LocationCard
  min-width: var(--lib-min-card-width);
  width: inherit;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);
  margin-bottom: 2px;

  background-color: var(--lib-foreground);
`;
