/*
  StyledHeader is a template component, extended by StyledLandingHeader and StyledLocationsHeader.
  The extensions configure StyledHeader with four local CSS variables: 
  --header-bg-color, --header-fg-color, --header-grid-flow-main, --title-size,
  and override styles with additional rules.
*/

import styled from "styled-components";
import { Breakpoints } from "../AppTheme";

export const StyledHeader = styled.header`
  width: 100%;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: 1fr;
  grid-auto-rows: min-content;
  grid-auto-columns: 1fr auto; //brand, search (locs) -> 1col (landing)
  /* grid-auto-rows: 1fr min-content; //brand, search (landing) -> 1col (locs) */
  grid-auto-flow: var(--header-grid-flow-main);

  background-color: var(--header-bg-color);
`;

export const StyledBrandContainer = styled.div`
  box-sizing: border-box;

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  h1 {
    color: var(--header-fg-color);
    font-size: var(--title-size);
  }

  a {
    padding: 0.5rem;
    margin: -0.5rem;
    vertical-align: middle;
  }

  button {
    background-color: var(--header-bg-color);
    border: none;
  }

  img {
    height: var(--title-size);
    vertical-align: inherit;
    cursor: pointer;
  }
`;

export const StyledSearchContainer = styled.div`
  box-sizing: border-box;
  width: 100%;

  display: grid;
  grid-auto-flow: var(--header-grid-flow-main);
  align-content: center;

  background-color: var(--app-primary-color);

  @media ${Breakpoints.mobile} {
    justify-content: normal;
  }
`;

export const StyledSearchContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a,
  a:hover,
  a:focus,
  a:active {
    color: #fff !important;
    white-space: nowrap;
  }
`;

export const StyledLandingHeader = styled(StyledHeader)`
  //Landing Variables passed down to children
  --header-bg-color: var(--lib-foreground);
  --header-fg-color: var(--app-primary-color);
  --header-grid-flow-main: row;
  --title-size: var(--lib-text-size-5);

  ${StyledBrandContainer} {
    padding: var(--lib-default-spacing);
    justify-content: center;
  }

  ${StyledSearchContainer} {
    padding: var(--lib-default-spacing);
    justify-content: center;

    grid-template-columns: minmax(min-content, 25rem);

    h2 {
      width: 100%;
      margin-bottom: var(--lib-default-spacing);
      display: inline;
      font-size: var(--lib-text-size-3);
      text-align: start;
      text-transform: uppercase;
      color: var(--header-bg-color);
      justify-self: center;
    }
  }
`;

export const StyledLocationsHeader = styled(StyledHeader)`
  //Locations Variables passed down to children
  --header-fg-color: var(--lib-foreground);
  --header-bg-color: var(--app-primary-color);
  --header-grid-flow-main: column;
  --title-size: var(--lib-text-size-4);

  padding: var(--lib-default-spacing);
  // Align perfectly with List
  padding-left: calc(var(--lib-default-spacing) + 3px);
  gap: var(--lib-default-spacing);
  justify-content: space-between;

  h2 {
    display: none;
  }

  ${StyledBrandContainer} {
    padding: 0rem;
    justify-content: start;
  }

  ${StyledSearchContainer} {
    padding: 0rem;
    justify-self: end;
    justify-content: end;

    grid-template-columns: minmax(min-content, 25rem);
  }

  ${StyledSearchContainerTop} {
    display: none;
  }

  @media ${Breakpoints.mobile} {
    ${StyledBrandContainer} {
      width: 25%;

      img {
        height: var(--lib-text-size-4);
      }
    }

    //hide text on mobile
    h1,
    h2 {
      display: none;
    }
  }
`;
