import styled from "styled-components";
import { StyledLandingPageProps } from "./Landing.types";

export const StyledLanding = styled.div`
  height: inherit;
  width: inherit;

  display: grid;
  grid-template-rows: min-content 1fr min-content; //header content footer

  background-color: var(--lib-background);

  #page-content {
    width: inherit;
    padding: var(--lib-default-spacing);
    box-sizing: border-box;
    overflow-y: auto;

    //align text-cards
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    > * + * {
      margin-top: var(
        --lib-default-spacing
      ); //alternative for flex-gap - google "lobotomized owl selector"
    }
  }

  #goto-buttons {
    width: 100%;
    height: auto;
    max-width: 25rem;
    display: grid;
    grid-auto-flow: column;
    gap: var(--lib-default-spacing);
  }

  #map-card,
  #list-card {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--lib-default-spacing);
  }

  #goto-list-card-wrapper {
    display: none;
  }

  #myloc-btn * {
    padding-right: 0.35rem !important;
  }

  @media screen and (max-width: 40rem) {
    #goto-list-card-wrapper {
      display: inherit;
    }

    #map-card,
    #list-card {
      aspect-ratio: 5/1.5;
    }
  }

  ${({ backgroundImgSrc }: StyledLandingPageProps) =>
    backgroundImgSrc &&
    `
    #page-content {
      background: linear-gradient(
          rgba(0, 0, 0, var(--app-landing-cover-darkness)),
          rgba(0, 0, 0, var(--app-landing-cover-darkness))
        ),
        url('${backgroundImgSrc}') center;
      background-size: cover;
    `}
`;
