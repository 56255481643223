import styled from "styled-components";
import { StyledImageCardProps } from "./ImageCard.types";

export const StyledImageCard = styled.div`
  width: 100%;
  max-width: 25rem;
  height: auto;
  padding: var(--lib-default-spacing);
  box-sizing: border-box;

  background: var(--lib-foreground);
  --shadow: var(--lib-shadow) 0px 2px 4px;
  box-shadow: var(--shadow);
  color: var(--lib-secondary-gray);

  ${({ imgSrcDefault }: StyledImageCardProps) =>
    imgSrcDefault &&
    `
      background: url('${imgSrcDefault}') no-repeat center center;
      background-size: 100% 100%;
    `}

  ${({ imgSrcNarrow }: StyledImageCardProps) =>
    imgSrcNarrow &&
    `
    @media screen and (max-width: 40rem) {
        background: url('${imgSrcNarrow}') no-repeat center center;
        background-size: 100% 100%;
    }`}
`;
