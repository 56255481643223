import React from "react";
import { ServiceOptionProps } from "./shared.types";
import styled from "styled-components";

import { v4 as uuidv4 } from "uuid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

const StyledServiceOption = styled.span`
  font-size: var(--lib-text-size-0);
  color: var(--lib-primary-green);

  display: inline-block;

  > :last-child {
    padding-inline-start: 5px;
  }
`;

const ServiceOption = ({ label }: ServiceOptionProps) => {
  return (
    <StyledServiceOption key={uuidv4()}>
      <FontAwesomeIcon aria-hidden={true} icon={faCheck}></FontAwesomeIcon>
      <span>{label}</span>
    </StyledServiceOption>
  );
};

export default ServiceOption;
