import styled from "styled-components";

export const StyledNoResults = styled.div`
  padding: 2rem;
  box-sizing: border-box;

  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  gap: var(--lib-default-spacing);

  text-align: center;
`;

export const StyledH2 = styled.h2`
  font-size: var(--lib-text-size-3);
`;

export const StyledMessage = styled.p`
  font-size: var(--lib-text-size-0);
`;
