// Framework and third-party non-ui
import React from "react";
import { StyledModalGrid, StyledModalTitle, StyledCalciteModal } from "./PhoneModal-styled";
import { PhoneModalProps } from "./PhoneModal.types";

const PhoneModal = ({ children }: PhoneModalProps) => {
  return (
    <StyledCalciteModal id="phone-modal" open width="s">
      <StyledModalTitle slot={"header"}>Emergency Hotlines:</StyledModalTitle>
      <StyledModalGrid slot={"content"}>{children}</StyledModalGrid>
    </StyledCalciteModal>
  );
};

export default PhoneModal;
