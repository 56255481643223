import Graphic from "@arcgis/core/Graphic";
import { Utils } from "nyc-component-lib";
import { UpdateFocalPointArgs } from "./AppContext.types";

const _updateFocalPoint = async (
  { graphic, position, isHidden }: UpdateFocalPointArgs,
  setFocalPoint: (g?: Graphic) => void
) => {
  if (graphic) {
    // ensure that a point geometry is in WKID 4326 for consistency
    // (the spatial reference matters when doing proximity calculations elsewhere)
    graphic.geometry = Utils.geometryToGeographic(graphic.geometry);
    graphic.attributes["isHidden"] = isHidden || false;
  } else if (position) {
    // turn the position into a Graphic with point geometry in WKID 4326
    graphic = Utils.geolocationToPointGraphic(position);
    graphic.attributes = { isHidden: isHidden || false };
  }

  // This is a (non-ideal) solution to a bug resulting from the nyc-component-lib Map component calling the getLocCard function above and causing the , where the focalPoint is perpetually interpreted as undefined by the onOpenDirections() function to perpetually receive an undefined value for its 2nd (focalPoint) argument.  This bug is likely caused by the fact that we're using the ArcGIS JSAPI (which is stateless) in the Map component to create a custom content for the PopupTemplate, in conjunction with React, which is statefull. While not ideal, setting a global focalPoint property to the window appears to fix this bug.
  window.focalPoint = graphic;

  setFocalPoint(graphic);
};

export { _updateFocalPoint };
