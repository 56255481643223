/*
  LocationTextLine is a utility component formatting child elements with separator characters
*/

import React from "react";
import { Children } from "react";
import { LocTextLineProps } from "./shared.types";
import styled from "styled-components";

const StyledP = styled.p`
  font-size: inherit;
  > :not(:last-child) {
    :after {
      content: " • ";
      color: var(--lib-secondary-gray);
    }
  }
`;

const LocationTextLine = ({ children }: LocTextLineProps) => {
  const childArr = children ? Children.toArray(children) : [];
  return childArr.filter((x) => x !== "").length !== 0 ? <StyledP>{children}</StyledP> : null;
};

export default LocationTextLine;
