import React from "react";
import styled from "styled-components";
import { PhoneButtonProps } from "./PhoneButton.types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons";

const StyledPhoneButton = styled.button`
  // same as TextCard
  width: 100%;
  max-width: 25rem;
  height: min-content;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);

  border-radius: 50px;
  border-style: none;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--lib-default-spacing);
  align-items: center;

  color: #fff;
  background-color: var(--lib-primary-orange);
  font-size: var(--lib-text-size-0);

  :hover {
    background-color: var(--lib-primary-orange-hover);
  }
  :active {
    background-color: var(--lib-primary-orange-press);
  }
`;

const StyledButtonText = styled.span`
  text-align: start;
`;

const PhoneButton = ({ message }: PhoneButtonProps) => {
  return (
    <StyledPhoneButton>
      <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
      <StyledButtonText>{message}</StyledButtonText>
    </StyledPhoneButton>
  );
};

export default PhoneButton;
