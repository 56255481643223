import styled from "styled-components";
import { Breakpoints } from "nyc-component-lib";

export const StyledFabContainer = styled.div`
  display: none;

  @media ${Breakpoints.mobile} {
    display: block;

    position: fixed;
    //Center button on screen without div overlapping "Load all" button
    left: 50%;
    transform: translateX(-50%);
    //2px added to vertically center FAB and Load All
    bottom: calc(var(--lib-default-spacing) + 2px);

    z-index: 1; //raise above cards
  }
`;

export const StyledLocationsView = styled.div`
  height: 100%;
  width: inherit;

  display: grid;
  // Sol'n for list overflow: https://css-tricks.com/preventing-a-grid-blowout/
  grid-template-rows: min-content min-content minmax(0, 1fr); //header filter content

  background-color: #efefef;

  #page-content {
    display: grid;
    width: inherit;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns:
      minmax(var(--lib-min-card-width), var(--lib-max-card-width))
      minmax(var(--lib-min-card-width), 1fr); //list map
  }

  #list-col-wrapper {
    overflow-y: auto;
  }

  #filter-row-wrapper {
    width: inherit;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: var(--lib-foreground);
  }

  @media ${Breakpoints.mobile} {
    #list-col-wrapper.hide {
      display: none;
    }
  }

  @media ${Breakpoints.mobile} {
    #page-content {
      grid-template-columns: 1fr;
    }
  }
`;

export const StyledListH2 = styled.h2`
  width: inherit;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);
  //See StyledList
  margin: 3px 2px 3px 3px;
  border: 2px solid #fff;

  background-color: var(--lib-foreground);
  color: var(--lib-secondary-gray);
  font-size: var(--lib-text-size-2);
  font-weight: 500; //prevent extra tracking on Safari
  text-decoration: none;
`;

export const StyledFilterH2 = styled(StyledListH2)`
  width: min-content;
  margin: 0px;
  border: 0px;
`;
