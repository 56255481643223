import styled from "styled-components";
import { IconBtnStyles } from "./IconButton.types";

export const StyledButton = styled.button`
  /* border: none; */
  font-size: 14px;
  cursor: pointer;

  height: ${({ height }: IconBtnStyles) => height};
  padding: ${({ height }: IconBtnStyles) => (height === "31px" ? "0.5rem 1rem" : "1rem 1.5rem")};
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25); */
  border: 1px solid var(--lib-shadow);

  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 0.5rem; */

  background-color: #fff;
  color: var(--app-primary-color);

  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  }

  & :first-child {
    margin-right: 1rem;
  }
`;
