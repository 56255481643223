import styled from "styled-components";
import { Breakpoints } from "../../AppTheme";
import { CheckmarkFilterProps } from "../Filter.types";

export const StyledBooleanFilter = styled.span<CheckmarkFilterProps>`
  ${({ isChecked }: CheckmarkFilterProps) =>
    `  @media ${Breakpoints.mobile} {
      margin-left: ${isChecked === true ? "0" : "28px"};
  }`}
`;
