import React from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../../utils/i18n";

import { v4 as uuidv4 } from "uuid";

import { ServiceOption } from "../../shared";
import { StyledMinMaxSection, StyledSectionHeading } from "../Details-styled";
import { MinMaxSectionDetails } from "../Details.types";

const MinMaxSection = ({ loc, serviceMinMaxValues, sectionTitle }: MinMaxSectionDetails) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });
  return (
    <I18nextProvider i18n={i18n}>
      <StyledMinMaxSection key={"detailsMinMax"}>
        {sectionTitle && <StyledSectionHeading>{sectionTitle}</StyledSectionHeading>}
        {serviceMinMaxValues
          .map((minMaxConfig) => {
            const min = loc.attributes[minMaxConfig.serviceMinFieldname];
            const max = loc.attributes[minMaxConfig.serviceMaxFieldname];
            if (min || max) {
              return (
                <span className="minmax-row" key={uuidv4()}>
                  <ServiceOption label={minMaxConfig.fieldLabel} key={uuidv4()}></ServiceOption>
                  <span key={uuidv4()}>{`${min || 0}-${max || 0}`}</span>
                </span>
              );
            } else {
              return null;
            }
          })
          .filter((item) => item !== null)}
      </StyledMinMaxSection>
    </I18nextProvider>
  );
};

export default MinMaxSection;
