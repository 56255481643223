import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { TFunction } from "react-i18next";
import { updateDomainFields } from "../../utils";
import { LocationsLayerInfo } from "../shared/shared.types";
import { updateOpenNow } from "./OpenNowHelper";

const _getServices = (
  loc: Graphic,
  format: "byvalue" | "notnull",
  fieldLabels: any,
  fields?: string[]
) => {
  const services: string[] = [];

  fields?.forEach((field) => {
    const currentField = loc.attributes[`${field}`];
    if (currentField) {
      // "notnull" example: childcare has age groups with a max age field; if max age is not null then the location has that age group
      // "byvalue" example: food help has "food panty type" and "soup kitchen type" fields where the value of the fields is the service e.g. "soup kitchen - kosher"
      if (format === "byvalue") {
        services.push(currentField);
      } else if (format === "notnull") {
        services.push(fieldLabels[field]);
      }
    }
  });

  return services.length === 0 ? undefined : services;
};

export const getLocCardDetails = ({
  loc,
  locationLayerInfo,
  locationLayer,
  hideDistance,
  t,
  fieldLabels,
}: {
  loc: Graphic;
  locationLayerInfo: LocationsLayerInfo;
  locationLayer?: FeatureLayer;
  hideDistance?: boolean;
  t: TFunction;
  fieldLabels: any;
}) => {
  if (locationLayer && locationLayerInfo.domainFieldNames) {
    updateDomainFields([loc], locationLayer, locationLayerInfo.domainFieldNames);
  }

  const locAttr = loc?.attributes || {};
  const phone = locationLayerInfo.phoneFieldName ? locAttr[locationLayerInfo.phoneFieldName] : "";
  const address = locationLayerInfo.addressFieldName
    ? locAttr[locationLayerInfo.addressFieldName]
    : "";
  const objectId = locAttr[locationLayerInfo.objectIdFieldName];

  const locMessage = locationLayerInfo.locMessageFieldNames
    ?.map((fieldName) => locAttr[fieldName])
    .filter((note) => !!note)
    .join(" - ");

  // ListHelper updates isOpenNow for location objects in the List because it's slightly more efficient to do en masse
  // but Cards that will show in map popup need to have the location updated here
  if (
    locationLayerInfo.openHoursServiceTypes &&
    locationLayerInfo.openHoursTimeWindows &&
    locAttr.isOpenNow !== true &&
    locAttr.isOpenNow !== false
  ) {
    updateOpenNow(
      [loc],
      locationLayerInfo.openHoursServiceTypes,
      locationLayerInfo.openHoursTimeWindows,
      t
    );
  }
  const isOpen = loc?.attributes?.isOpenNow;
  let hoursNote = loc?.attributes?.nextCloseTime
    ? t("locCard.closesAt", { closeTime: loc.attributes.nextCloseTime })
    : undefined;
  if (!hoursNote && loc?.attributes?.nextOpenTime) {
    hoursNote = t("locCard.opensAt", { openTime: loc?.attributes?.nextOpenTime });
  }

  const locIsAccessible =
    locationLayerInfo.accessibleFieldName && locationLayerInfo.accessibleFieldValue
      ? loc?.attributes[locationLayerInfo.accessibleFieldName] ===
        locationLayerInfo.accessibleFieldValue
      : false;

  const locIsPetFriendly =
    locationLayerInfo.petFriendlyFieldName && locationLayerInfo.petFriendlyFieldValue
      ? loc?.attributes[locationLayerInfo.petFriendlyFieldName] ===
        locationLayerInfo.petFriendlyFieldValue
      : false;

  const cardDetails = {
    locTitle: locAttr[locationLayerInfo.titleFieldName],
    locType: locationLayerInfo.locTypeFieldName
      ? locAttr[locationLayerInfo.locTypeFieldName]
      : undefined,
    locDist:
      !hideDistance && locAttr.proximity
        ? t("locCard.milesAbbrev", { proximity: locAttr.proximity })
        : undefined,
    locAddress: address,
    locStatus: { isOpen, hoursNote },
    locPhone: phone,
    locServices: _getServices(
      loc,
      locationLayerInfo.servicesFormat,
      fieldLabels,
      locationLayerInfo.serviceTypeFieldNames
    ),
    locMessage: locMessage,
    locFID: objectId,
    locIsAccessible,
    locIsPetFriendly,
  };
  return cardDetails;
};
