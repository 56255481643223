// Framework and third-party non-ui
import React, { useRef, useEffect } from "react";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

import PropTypes from "prop-types";
import { StyledTranslationContainer } from "./TranslationSelectGoogle-styled";

// NOTE that this component requires scripts to be added to the index.html of the project to instanciate the Google Translate widget

const TranslationSelectGoogle = () => {
  const { t, i18n } = useTranslation("components", { i18n: instance });
  const translateContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const translateElem = document.getElementById("google_translate_element");
    if (translateContainer && translateContainer.current && translateElem) {
      translateContainer.current.appendChild(translateElem);
    }
    // when component unmounts, put the translate element back into the body so it is preserved when the back button is clicked
    return () => {
      if (translateElem) {
        document.body.appendChild(translateElem);
      }
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <StyledTranslationContainer
        aria-label={t("translation.widgetAriaLabel")}
        ref={translateContainer}
        role="navigation"
      ></StyledTranslationContainer>
    </I18nextProvider>
  );
};

TranslationSelectGoogle.propTypes = {
  noPosition: PropTypes.bool,
};

TranslationSelectGoogle.defaultProps = {};

export default TranslationSelectGoogle;
