import React from "react";
import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";
import { TFunction } from "i18next";

import { StyledButton } from "./IconButton-styled";
import { IconBtnProps } from "./IconButton.types";
import { isIOS, isMobile } from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faMap,
  faListUl,
  faCircleQuestion,
  faShareNodes,
  faNotdef,
  faArrowUpFromSquare,
  faArrowUpRightFromSquare,
  faDiamondTurnRight,
  faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import {} from "@fortawesome/pro-light-svg-icons";

type btnDef = {
  icon: IconProp;
  color: string;
  bgColor: string;
  isPill: boolean;
  height: string;
};

const getIconBtnDef = (label: string, t: TFunction): btnDef => {
  switch (label) {
    case t("iconButton.viewMap"):
      return {
        icon: faMap,
        color: "#fff",
        bgColor: "var(--app-primary-blue)",
        isPill: true,
        height: "38px",
      };

    case t("iconButton.viewList"):
      return {
        icon: faListUl,
        color: "#fff",
        bgColor: "var(--app-primary-blue)",
        isPill: true,
        height: "38px",
      };

    case t("iconButton.zoomTo"):
      return {
        icon: faMagnifyingGlass,
        color: "#fff",
        bgColor: "var(--app-primary-green)",
        isPill: false,
        height: "32px",
      };

    case t("iconButton.faq"):
      return {
        icon: faCircleQuestion,
        color: "var(--app-primary-blue)",
        bgColor: "#fff",
        isPill: false,
        height: "31px",
      };

    case t("iconButton.share"):
      return {
        icon: isMobile ? (isIOS ? faArrowUpFromSquare : faArrowUpRightFromSquare) : faShareNodes,
        color: "var(--app-primary-blue)",
        bgColor: "#fff",
        isPill: false,
        height: "34px",
      };

    case t("iconButton.directions"):
      return {
        icon: faDiamondTurnRight,
        color: "var(--app-primary-blue)",
        bgColor: "#fff",
        isPill: false,
        height: "31px",
      };

    default:
      return {
        icon: faNotdef,
        bgColor: "var(--app-primary-blue)",
        color: "#fff",
        isPill: true,
        height: "38px",
      };
  }
};

const IconButton = ({ label, onClick }: IconBtnProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  const btnValues = getIconBtnDef(label, t);
  return (
    <I18nextProvider i18n={i18n}>
      <StyledButton
        onClick={onClick}
        color={btnValues?.color}
        bgColor={btnValues?.bgColor}
        isPill={btnValues?.isPill}
        height={btnValues?.height}
      >
        <FontAwesomeIcon icon={btnValues.icon} style={{ fontSize: 14 }}></FontAwesomeIcon>
        <span>{label}</span>
      </StyledButton>
    </I18nextProvider>
  );
};

export default IconButton;
