// Framework and third-party non-ui
import React, { createContext, useContext, useState, useEffect } from "react";
import { MapController } from "nyc-component-lib";
import { AppContext as AppContextType, UpdateFocalPointArgs } from "./AppContext.types";
import MapView from "@arcgis/core/views/MapView";

import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { _updateFocalPoint } from "./AppController";
import { AppConfig } from "./AppConfig.types";

export const AppContext = createContext<AppContextType | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppContextProvider = ({ children, isAppActive }: any) => {
  const [appConfig, setAppConfig] = useState<AppConfig | undefined>();
  const [mapView, setMapView] = useState<MapView>();
  const [locationsFeatureLayer, setLocationsFeatureLayer] = useState<FeatureLayer | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [focalPoint, setFocalPoint] = useState<Graphic | undefined>();
  const [filterWhere, setFilterWhere] = useState<string>("");

  const updateMapView = async (mapView: MapView) => {
    setMapView(mapView);
  };

  const updateLocationsFeatureLayer = (layer: FeatureLayer) => {
    setLocationsFeatureLayer(layer);
  };

  const updateMapHighlight = (newHighlight?: __esri.Handle) => {
    // This fixes bug 35678
    // and bug 36330
    // Previously used useState here but
    // seems to be an async issue with losing handles.
    if (window.mapHighlight) window.mapHighlight.remove();
    window.mapHighlight = newHighlight;
  };

  const updateFilterWhere = async (where: string) => {
    setFilterWhere(where);
  };

  const updateFocalPoint = (args: UpdateFocalPointArgs) => {
    _updateFocalPoint(args, setFocalPoint);
  };

  const updateSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    if (mapView) {
      mapView.on("click", () => {
        updateMapHighlight();
      });
    }
    // setting exhaustive-deps causes loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapView]);

  useEffect(() => {
    if (mapView) {
      mapView.popup.close();
      MapController.goToLocationAndShowPin({
        mapView,
        layerUrl: `${window.activeMapConfig?.loc_layer_url}`,
        pinPoint: focalPoint,
        pinImageUrl: `${process.env.PUBLIC_URL}/mappin.svg`,
        updateMapHighlight,
        objectIdFieldname: appConfig?.fields.locObjectIDFieldname || "FID",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapView, focalPoint]);

  useEffect(() => {
    if (mapView) {
      MapController.updateFilterOnMapView(
        mapView,
        `${window.activeMapConfig?.loc_layer_url}`,
        filterWhere
      );
    }
  }, [filterWhere, mapView]);

  return (
    <AppContext.Provider
      value={{
        appConfig,
        updateAppConfig: setAppConfig,
        mapView,
        updateMapView,
        locationsFeatureLayer,
        updateLocationsFeatureLayer,
        searchTerm,
        updateSearchTerm,
        focalPoint,
        updateFocalPoint,
        filterWhere: filterWhere,
        updateFilterWhere,
        updateMapHighlight,
        isActive: isAppActive,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    // the below text is for developers not for users. It does not need to be translated
    throw new Error("Cannot use 'useAppContext' outside of a AppContextProvider");
  }
  return appContext;
};
