import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import LayerSearchSource from "@arcgis/core/widgets/Search/LayerSearchSource";
import LocatorSearchSource from "@arcgis/core/widgets/Search/LocatorSearchSource";
import { LayerSourceInfo, LocatorSourceInfo, OnSearchCompleteProps } from "./Search.types";

import { TFunction } from "react-i18next";

const _getFeatureLayerSource = ({ url, fieldName, title }: LayerSourceInfo, t: TFunction) => {
  if (url.indexOf("FeatureLayer") > 0) {
    throw new Error(t("search.searchLayerError", { url: url }));
  }
  return new LayerSearchSource({
    layer: new FeatureLayer({ url }),
    searchFields: [fieldName],
    displayField: fieldName,
    exactMatch: false,
    outFields: ["*"],
    name: title || t("search.byLocationName"),
    maxResults: 6,
    maxSuggestions: 4,
    suggestionsEnabled: true,
    minSuggestCharacters: 0,
  });
};

export const _getLocatorSource = ({ url, title }: LocatorSourceInfo, t: TFunction) => {
  return new LocatorSearchSource({
    name: title || t("search.byAddress"),
    singleLineFieldName: "SingleLine",
    url,
    maxResults: 6,
    maxSuggestions: 4,
    suggestionsEnabled: true,
    minSuggestCharacters: 0,
  });
};

const _handleSearchComplete = (
  e: __esri.SearchSearchCompleteEvent,
  onSearchComplete?: (x: OnSearchCompleteProps) => void,
  onSearchError?: (x: unknown) => void
) => {
  try {
    if (e.numResults > 0) {
      const focalPointGraphic = e.results.find((r) => r.results?.length > 0)?.results[0].feature;
      onSearchComplete?.({ searchTerm: e.searchTerm, graphic: focalPointGraphic });
    } else if (e.errors?.length > 0) {
      onSearchError?.(e.errors);
    }
  } catch (error: unknown) {
    onSearchError?.(error);
  }
};

export { _getFeatureLayerSource, _handleSearchComplete };
