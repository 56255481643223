import React from "react";
import { ShareBtnProps } from "./ShareButton.types";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";

import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";
import IconButton from "../IconButton";
import { isIOS, isMobile } from "react-device-detect";
import styled from "styled-components";

const StyledSpan = styled.span`
  padding: 1px 0; // offset to match Calcite dropdown height
  box-sizing: border-box;
`;

const onShareClick = (shareUrl: string) => {
  if (navigator.share) {
    navigator
      .share({
        title: document.title,
        url: shareUrl,
      })
      .then(() => {
        console.log("Address shared");
      })
      .catch(console.error);
  } else {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        alert("The address is copied to the clipboard!");
      })
      .catch(console.error);
  }
};

const ShareButton = ({ shareUrl }: ShareBtnProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });
  return (
    <I18nextProvider i18n={i18n}>
      {isMobile ? (
        <CalciteButton
          class="lib-neutral-button"
          id="share-btn"
          onClick={() => onShareClick(shareUrl)}
          appearance="outline"
          iconStart={isIOS ? "share-ios" : "share"}
          color="neutral"
        >
          <StyledSpan>{t("share")}</StyledSpan>
        </CalciteButton>
      ) : (
        <IconButton label={t("share")} onClick={() => onShareClick(shareUrl)}></IconButton>
      )}
    </I18nextProvider>
  );
};

export default ShareButton;
