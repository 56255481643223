import styled from "styled-components";

export const StyledMap = styled.div`
  /*
  Note: map will "disappear" if not a child of another element
  that has height, width set in fixed units (not percentages), 
  or that inherits from one
  */
  height: 100%;
  width: 100%;

  /* JSAPI style overrides for popup container */

  .esri-popup__header,
  .esri-popup__footer {
    display: none !important;
  }

  .esri-popup * {
    margin: 0rem !important;
    font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;

    h3 {
      color: var(--lib-primary-gray) !important;
      font-size: var(--lib-text-size-1) !important;
      font-weight: bold !important;

      a {
        color: inherit;
      }
    }

    p {
      font-size: var(--lib-text-size-0) !important;
    }

    a {
      color: var(--lib-secondary-blue);
    }
  }
`;
