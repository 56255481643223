// Framework and third-party non-ui
import React, { useEffect, useRef, useState } from "react";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

import { v4 as uuidv4 } from "uuid";

// Component specific modules (Component-styled, etc.)
import {
  StyledDetailsGrid,
  StyledCardSection,
  StyledMapSection,
  StyledLocationTitle,
  StyledSectionHeading,
} from "./Details-styled";
import { DetailsProps } from "./Details.types";
import MinMaxSection from "./DetailSections/MinMaxSection";
import OperatingHoursSection from "./DetailSections/OperatingHoursSection";
import SingleFieldSection from "./DetailSections/SingleFieldSection";
import { _getLocationById } from "./DetailsHelper";

// App and lib components
import { FooterBar, Map, MapController } from "../../components";
import {
  getDistanceFromFocalPoint,
  onOpenDirections,
  roundToTwo,
  updateDomainFields,
} from "../../utils";
import { LocationTextLine, LocationTitleIcons } from "../shared";

// Third-party components (buttons, icons, etc.)
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";

import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-modal";
import { CalciteButton, CalciteModal } from "@esri/calcite-components-react";

const initialScale = 36111.909643; // LOD 14: https://developers.arcgis.com/documentation/mapping-apis-and-services/reference/zoom-levels-and-scale/

const Details = ({
  locationId,
  focalPoint,
  locationsFeatureLayer,
  updateMapHighlight,
  locationLayerInfo,
  locationLayerUrl,
  mapId,
  onClose,
  onZoomTo,
  onCardClick,
  onPopupUpdate,
  detailSections,
  updateGoogleTranslate,
  fieldLabels,
}: DetailsProps) => {
  const [loc, setLoc] = useState<Graphic | undefined>();
  const [detailMapView, setDetailMapView] = useState<MapView | undefined>();
  const [proximity, setProximity] = useState<number | undefined>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { t, i18n } = useTranslation("components", { i18n: instance });

  const titleElement = useRef<HTMLHeadingElement>(null);
  const modalElement = useRef<HTMLCalciteModalElement>(null);

  // query for specific location in case user arrives at this view first
  useEffect(() => {
    const getLocation = async () => {
      if (locationId && locationsFeatureLayer) {
        try {
          const locResult = await _getLocationById(parseInt(locationId), locationsFeatureLayer);
          if (locationsFeatureLayer && locationLayerInfo.domainFieldNames) {
            updateDomainFields(
              [locResult],
              locationsFeatureLayer,
              locationLayerInfo.domainFieldNames
            );
          }
          setLoc(locResult);
          if (focalPoint) {
            setProximity(roundToTwo(getDistanceFromFocalPoint(focalPoint, locResult)) || undefined);
          }
        } catch (error) {
          console.warn(error);
        }
      }
    };
    getLocation();
    // excluding servicesActive from the dependency array to avoid infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, locationsFeatureLayer, locationLayerInfo.serviceTypeFieldNames]);

  const setFocus = () => {
    if (titleElement && titleElement.current) {
      titleElement.current.focus();
    }
  };

  useEffect(() => {
    setFocus();
    if (detailMapView && loc) {
      MapController.goToLocationAndShowPin({
        mapView: detailMapView,
        layerUrl: `${locationLayerUrl}`,
        pinPoint: loc,
        pinImageUrl: `${process.env.PUBLIC_URL}/mappin.svg`,
        updateMapHighlight,
        objectIdFieldname: locationLayerInfo.objectIdFieldName,
      });
    }
  }, [
    detailMapView,
    loc,
    locationLayerInfo.objectIdFieldName,
    locationLayerUrl,
    updateMapHighlight,
  ]);

  useEffect(() => {
    updateGoogleTranslate();
  }, [loc, updateGoogleTranslate]);

  useEffect(() => {
    if (modalElement && modalElement.current) {
      setModalOpen(true);
    }
  }, [loc]);

  const initialCenter = loc ? (loc.geometry as Point) : new Point({ x: 0, y: 0 });
  const locIsAccessible =
    locationLayerInfo.accessibleFieldName && locationLayerInfo.accessibleFieldValue
      ? loc?.attributes[locationLayerInfo.accessibleFieldName] ===
        locationLayerInfo.accessibleFieldValue
      : false;

  const locIsPetFriendly =
    locationLayerInfo.petFriendlyFieldName && locationLayerInfo.petFriendlyFieldValue
      ? loc?.attributes[locationLayerInfo.petFriendlyFieldName] ===
        locationLayerInfo.petFriendlyFieldValue
      : false;

  return (
    <I18nextProvider i18n={i18n}>
      {loc && (
        <CalciteModal
          id="details-modal"
          aria-labelledby={titleElement?.current?.id || ""}
          ref={modalElement}
          open={modalOpen}
          onCalciteModalClose={onClose}
        >
          {/* Card */}
          <StyledCardSection slot={"header"}>
            <div id="content">
              <StyledLocationTitle ref={titleElement} tabIndex={0}>
                {loc.attributes[locationLayerInfo.titleFieldName]}
                <LocationTitleIcons
                  isAccessible={locIsAccessible}
                  isPetFriendly={locIsPetFriendly}
                />
              </StyledLocationTitle>
              {locationLayerInfo.locTypeFieldName &&
                loc.attributes[locationLayerInfo.locTypeFieldName] && (
                  <LocationTextLine>
                    {loc.attributes[locationLayerInfo.locTypeFieldName]}
                  </LocationTextLine>
                )}
              {proximity && <LocationTextLine>{`${proximity} mi`}</LocationTextLine>}
              {locationLayerInfo.addressFieldName &&
                loc.attributes[locationLayerInfo.addressFieldName] && (
                  <LocationTextLine>
                    {loc.attributes[locationLayerInfo.addressFieldName]}
                  </LocationTextLine>
                )}
              {locationLayerInfo.phoneFieldName &&
                loc.attributes[locationLayerInfo.phoneFieldName] && (
                  <LocationTextLine>
                    <a href={`tel: ${loc.attributes[locationLayerInfo.phoneFieldName]}`}>
                      {loc.attributes[locationLayerInfo.phoneFieldName]}
                    </a>
                  </LocationTextLine>
                )}
            </div>
          </StyledCardSection>

          <StyledDetailsGrid slot={"content"}>
            {/* Detail sections */}
            {detailSections
              .map((section) => {
                if (section.isOperatingHours) {
                  return (
                    <OperatingHoursSection
                      key={uuidv4()}
                      loc={loc}
                      locationLayerInfo={locationLayerInfo}
                      sectionTitle={section.sectionTitle || t("details.hoursOfOperationTitle")}
                      disclaimer={section.disclaimer}
                    ></OperatingHoursSection>
                  );
                } else if (section.singleFieldname && loc.attributes[section.singleFieldname]) {
                  return (
                    <SingleFieldSection
                      key={uuidv4()}
                      loc={loc}
                      fieldName={section.singleFieldname}
                      sectionTitle={section.sectionTitle}
                    ></SingleFieldSection>
                  );
                } else if (section.serviceMinMaxValues) {
                  return (
                    <MinMaxSection
                      key={uuidv4()}
                      loc={loc}
                      serviceMinMaxValues={section.serviceMinMaxValues}
                      sectionTitle={section.sectionTitle}
                    ></MinMaxSection>
                  );
                } else {
                  return null;
                }
              })
              .filter((item) => item !== null)}

            {/* Map */}
            <StyledMapSection>
              <div id="map-section-header">
                <StyledSectionHeading>{t("details.mapHeader")}</StyledSectionHeading>
                <CalciteButton
                  id="zoom-to-btn"
                  class="lib-secondary-button"
                  onClick={() => onZoomTo(loc)}
                  appearance="solid"
                  iconStart="zoom-to-object"
                  label={t("details.zoomToLabel")}
                  color="blue" //"blue" reset to app secondary color in CSS
                >
                  {t("details.zoomTo")}
                </CalciteButton>
              </div>
              <Map
                itemId={mapId}
                initialScale={initialScale}
                initialCenter={initialCenter}
                targetLayerUrl={locationLayerUrl}
                locationsFeatureLayerInfo={locationLayerInfo}
                onError={() => {
                  return;
                }}
                onMapViewSet={setDetailMapView}
                isDetails={true}
                locationGraphicsLayerId={MapController.SELECTED_LOCATION_LAYER_ID}
                onCardClick={onCardClick}
                onPopupUpdate={onPopupUpdate}
                updateGoogleTranslate={updateGoogleTranslate}
                fieldLabels={fieldLabels}
              />
            </StyledMapSection>

            <FooterBar
              footerType="details"
              onOpenDirections={() => {
                onOpenDirections(loc, focalPoint);
              }}
            ></FooterBar>
          </StyledDetailsGrid>
        </CalciteModal>
      )}
    </I18nextProvider>
  );
};

export default Details;
