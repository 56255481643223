import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as intl from "@arcgis/core/intl.js";

import { StyledLanding } from "./Landing-styled";
import { LandingPageTypes } from "./Landing.types";
import { LinkInfo } from "../../contexts/AppConfig.types";

import { useAppContext } from "../../contexts/AppContext";
import {
  FooterBar,
  HeaderBar,
  TextCard,
  FinderSearch,
  OnSearchCompleteProps,
  ErrorTypes,
  Error as ErrorComponent,
  ImageCard,
  PhoneModal,
  PhoneButton,
  ModalWrapper,
} from "nyc-component-lib";

import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";

const LandingPage = ({ appTitle, sourceInfos, deactivated, modal }: LandingPageTypes) => {
  const { mapView, searchTerm, updateSearchTerm, updateFocalPoint, appConfig, updateMapHighlight } =
    useAppContext();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [errorMessage, setErrorMessage] = useState<ErrorTypes | undefined>();
  const [error, setError] = useState<unknown | undefined>();
  const [importantLinks, setImportantLinks] = useState<LinkInfo[] | undefined>();

  useEffect(() => {
    if (t && appConfig?.appId && appConfig?.landing?.importantLinks) {
      const linkStringsArray = t(`app.landing.importantLinks.${appConfig.appId}`, {
        returnObjects: true,
      });
      setImportantLinks(
        appConfig?.landing?.importantLinks.map((item, idx) => {
          return {
            ...item,
            ...linkStringsArray[idx],
          };
        })
      );
    }
  }, [appConfig, t]);

  const onError = (message?: ErrorTypes, error?: unknown) => {
    if (error) console.error(t("landing.error", { error: error }));
    setError(error || new Error(t("landing.unknownError")));
    setErrorMessage(message || ErrorTypes.UNKNOWN);
  };

  const onSearchClear = () => {
    updateSearchTerm("");
    updateFocalPoint({});
    updateMapHighlight();
  };

  const onSearchComplete = ({ searchTerm, graphic, position }: OnSearchCompleteProps) => {
    updateSearchTerm(searchTerm);
    updateFocalPoint({
      graphic,
      position,
      isHidden: false,
    });
    navigate("/locations");
  };

  const onSearchBlocked = () => {
    onError(ErrorTypes.SEARCH_BLOCKED);
  };

  const onSearchError = (error: unknown) => {
    onError(ErrorTypes.SEARCH, error);
  };

  const onGoToView = (showMap: boolean) => {
    updateFocalPoint({});
    navigate(showMap ? "/locations?mView=map" : "/locations");
  };

  const handleLocaleChange = (locale: string) => {
    intl.setLocale(locale);
    i18n.changeLanguage(locale);
  };

  const defaultMapCardImage =
    appConfig?.landing.mapCardBackgroundLarge !== undefined
      ? `${process.env.PUBLIC_URL}/${appConfig?.landing.mapCardBackgroundLarge}`
      : `${process.env.PUBLIC_URL}/img/NYCMapImageLargeScreen.png`;

  const smallMapCardImage =
    appConfig?.landing.mapCardBackgroundSmall !== undefined
      ? `${process.env.PUBLIC_URL}/${appConfig?.landing.mapCardBackgroundSmall}`
      : `${process.env.PUBLIC_URL}/img/NYCMapImageSmallScreen.png`;

  const showBannerProps = {
    isActive: !deactivated,
    message: `${appTitle} ${t("header.deactivated")}`,
  };

  return (
    <StyledLanding
      backgroundImgSrc={`${process.env.PUBLIC_URL}/${appConfig?.landing.backgroundImage}`}
    >
      {modal === true && (
        <ModalWrapper>
          <PhoneModal>
            <PhoneButton message="Emergencies: Call 911"></PhoneButton>
            <PhoneButton message="Crime Victim Hotline: 1-866-689-HELP"></PhoneButton>
            <PhoneButton message="Domestic Violence Hotline: 1-800-621-HOPE"></PhoneButton>
            <PhoneButton message="Report Child Abuse to the Administration for Children's Services: 1-800-342-3720"></PhoneButton>
          </PhoneModal>
        </ModalWrapper>
      )}
      <div id="header-container">
        <HeaderBar
          title={appTitle}
          headerType="landing"
          deactivated={deactivated}
          showBanner={showBannerProps}
          logoFirst={appConfig?.appInfo.logoFirst}
          onHomeClick={() => {
            navigate("/");
          }}
        >
          {sourceInfos && (
            <FinderSearch
              mapView={mapView}
              sourceInfos={sourceInfos}
              searchTerm={searchTerm}
              isLanding={true}
              onSearchClear={onSearchClear}
              onSearchComplete={onSearchComplete}
              onSearchBlocked={onSearchBlocked}
              onSearchError={onSearchError}
            />
          )}
        </HeaderBar>
      </div>

      <div id="page-content">
        {appConfig && (
          <TextCard
            opacity={appConfig?.landing.textCardOpacity || 1}
            title={t(`app.landing.aboutCardTitle.${appConfig.appId}`)}
          >
            <p
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: deactivated
                  ? t(`app.landing.aboutCardTextInactive.${appConfig.appId}`)
                  : t(`app.landing.aboutCardText.${appConfig.appId}`),
              }}
            ></p>
          </TextCard>
        )}
        {deactivated === false && (
          <div id="goto-buttons">
            <ImageCard imgSrcDefault={defaultMapCardImage} imgSrcNarrow={smallMapCardImage}>
              <div id="map-card">
                <CalciteButton
                  id="goto-map-btn"
                  onClick={() => {
                    onGoToView(true);
                  }}
                  iconStart="map-pin"
                  color="blue"
                  appearance="solid"
                  round
                >
                  {t("landing.viewMap")}
                </CalciteButton>
              </div>
            </ImageCard>
            <span id="goto-list-card-wrapper">
              <ImageCard imgSrcDefault={`${process.env.PUBLIC_URL}/img/NYCListImage.png`}>
                <div id="list-card">
                  <CalciteButton
                    id="goto-list-btn"
                    onClick={() => {
                      onGoToView(false);
                    }}
                    iconStart="list"
                    color="blue"
                    appearance="solid"
                    round
                  >
                    {t("landing.viewList")}
                  </CalciteButton>
                </div>
              </ImageCard>
            </span>
          </div>
        )}
        {importantLinks?.length && (
          <TextCard
            opacity={appConfig?.landing.textCardOpacity || 1}
            title={t("landing.importantLinks")}
          >
            <ul>
              {importantLinks.map((linkInfo) => {
                return (
                  <li key={linkInfo.url}>
                    {linkInfo.desc && <span>{linkInfo.desc}</span>}
                    <a
                      href={linkInfo.url}
                      target="_blank"
                      rel="noreferrer"
                      aria-label={linkInfo.ariaLabel}
                    >
                      {linkInfo.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </TextCard>
        )}
      </div>
      <FooterBar
        footerType="landing"
        faqUrl={appConfig?.appInfo.faqURL}
        onLocaleChange={appConfig?.useGoogleTranslate ? undefined : handleLocaleChange}
      ></FooterBar>
      <ErrorComponent message={errorMessage} error={error} />
    </StyledLanding>
  );
};

export default LandingPage;
