import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, parse, findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import { NumberIconProps } from "./shared.types";

const StyledIcon = styled(FontAwesomeIcon)`
  height: calc(var(--lib-default-spacing) * 2);
  aspect-ratio: 1;
`;

const StyledInactiveIcon = styled(StyledIcon)`
  & path {
    fill: var(--lib-primary-green);
  }
`;

const StyledActiveIcon = styled(StyledIcon)`
  & path {
    fill: var(--lib-primary-red);
  }
`;

const NumberIcon = ({ value, isActive }: NumberIconProps) => {
  const iconLookup = parse.icon(`square-${String(value)}`);
  const iconDef: IconDefinition = findIconDefinition(iconLookup);
  return (
    <StyledIcon as={isActive ? StyledActiveIcon : StyledInactiveIcon} icon={iconDef}></StyledIcon>
  );
};

export default NumberIcon;
