import React from "react";
import { LocTitleProps } from "./shared.types";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPawSimple, faWheelchairMove } from "@fortawesome/pro-solid-svg-icons";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

const StyledIcons = styled.span`
  font-size: var(--lib-text-size-0);
  color: var(--lib-primary-blue);
  text-decoration: none;
  display: inline-block;
  vertical-align: text-bottom;

  * {
    display: inline-block;
    padding-inline-start: 5px;
  }
`;

const LocationTitleIcons = ({ isAccessible, isPetFriendly }: LocTitleProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  return (
    <I18nextProvider i18n={i18n}>
      <StyledIcons>
        {isAccessible === true && (
          <FontAwesomeIcon
            icon={faWheelchairMove}
            aria-hidden={false}
            aria-label={t("booleanFilterBanner.accessible")}
          ></FontAwesomeIcon>
        )}
        {isPetFriendly === true && (
          <FontAwesomeIcon
            icon={faPawSimple}
            aria-hidden={false}
            aria-label={t("booleanFilterBanner.petFriendly")}
          ></FontAwesomeIcon>
        )}
      </StyledIcons>
    </I18nextProvider>
  );
};

export default LocationTitleIcons;
