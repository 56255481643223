import styled from "styled-components";

export const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  #searchDiv-source-menu-button {
    display: none;
  }

  #searchDiv-suggest-menu {
    width: 20rem;
  }

  #searchDiv {
    width: 100%;
    margin-inline-end: 0.5rem;
  }

  #myloc-btn {
    --calcite-ui-brand: white;
  }

  /* This stops mobile browsers from auto-zooming when focus is in the input box */
  .esri-search__input {
    font-size: 16px;
  }
`;

export const StyledSearchWrapperText = styled.span`
  margin-inline-end: 0.5rem;
  color: var(--lib-foreground);
`;
