import React from "react";
import { StyledIsOpenFilter } from "./IsOpenFilter-styled";
import { IsOpenNowFilterProps } from "../Filter.types";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../../utils/i18n";

import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";

const IsOpenFilter = ({ filterLabel, onClick, isApplied }: IsOpenNowFilterProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  return (
    <I18nextProvider i18n={i18n}>
      <StyledIsOpenFilter isChecked={isApplied}>
        <CalciteButton
          iconStart={isApplied ? "check" : ""}
          appearance={isApplied ? "solid" : "outline"}
          color={isApplied ? "blue" : "neutral"}
          class={isApplied ? "" : "lib-neutral-button"}
          onClick={onClick}
          label={`${filterLabel} ${isApplied ? t("filter.selected") : t("filter.unselected")}`}
        >
          {filterLabel}
        </CalciteButton>
      </StyledIsOpenFilter>
    </I18nextProvider>
  );
};

export default IsOpenFilter;
