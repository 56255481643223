import styled from "styled-components";

import "@esri/calcite-components/dist/components/calcite-modal";
import { CalciteModal } from "@esri/calcite-components-react";

export const StyledCalciteModal: typeof CalciteModal = styled(CalciteModal)`
  --calcite-ui-border-3: rgba(0, 0, 0, 0);
  --calcite-modal-width: var(--lib-min-card-width); //doesn't work
`;

//identical to StyledCardTitle
export const StyledModalTitle = styled.h2`
  padding-inline-start: var(--lib-default-spacing);

  font-size: var(--lib-text-size-1);
  color: var(--lib-primary-gray);
  font-weight: bold;
  text-decoration: none;
`;

export const StyledModalGrid = styled.div`
  width: 100%;
  height: min-content;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);
  padding-top: 5px; //otherwise header crops focus ring

  display: grid;
  grid-template-columns: 1fr;
  gap: var(--lib-default-spacing);

  background-color: var(--lib-foreground);
`;
