import React from "react";
import { StyledBooleanFilter } from "./BooleanFilter-styled";
import { BooleanFilterProps } from "../Filter.types";

import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPawSimple, faWheelchairMove } from "@fortawesome/pro-regular-svg-icons";

const BooleanFilter = ({
  onClick,
  isApplied,
  booleanFilterItem: { icon_calcite, icon_font_awesome, filter_label, show_label },
}: BooleanFilterProps) => {
  let faIcon;
  if (icon_font_awesome === "paw") {
    faIcon = faPawSimple;
  }
  if (icon_font_awesome === "wheelchair") {
    faIcon = faWheelchairMove;
  }

  return (
    <StyledBooleanFilter key={`${filter_label}`} isChecked={isApplied}>
      <CalciteButton
        iconStart={isApplied ? "check" : ""}
        iconEnd={icon_calcite ? icon_calcite : ""}
        appearance={isApplied ? "solid" : "outline"}
        color={isApplied ? "blue" : "neutral"}
        class={isApplied ? "" : "lib-neutral-button"}
        onClick={onClick}
        label={`${filter_label} ${isApplied ? "selected" : "unselected"}`}
      >
        {show_label ? filter_label : ""}
        {faIcon ? <FontAwesomeIcon icon={faIcon}></FontAwesomeIcon> : ""}
      </CalciteButton>
    </StyledBooleanFilter>
  );
};

export default BooleanFilter;
