import React from "react";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../../utils/i18n";

import { StyledNoResults, StyledMessage, StyledH2 } from "./NoResults-styled";
import { NoResultsProps } from "./NoResults.types";
import NoResultsGraphic from "./noresults.svg";

const NoResults = ({ message }: NoResultsProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });
  return (
    <I18nextProvider i18n={i18n}>
      <StyledNoResults>
        {/* Empty alt tag is best practice for decorative images */}
        <img src={NoResultsGraphic} alt="" aria-hidden={true} />
        <StyledH2>{t("noResults")}</StyledH2>
        <StyledMessage>{message}</StyledMessage>
      </StyledNoResults>
    </I18nextProvider>
  );
};

export default NoResults;
