import styled from "styled-components";
export const StyledCategoryFilter = styled.span``;

export const StyledPopoverContainer = styled.div`
  padding: var(--lib-default-spacing);
  overflow-y: auto;
  max-height: 50vh;
`;

// Select All, Close buttons
export const StyledPopoverButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
