import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import arLang from "../locales/ar.json";
import bnLang from "../locales/bn.json";
import zhCnLang from "../locales/zh-CN.json";
import frLang from "../locales/fr.json";
import htLang from "../locales/ht.json";
import itLang from "../locales/it.json";
import koLang from "../locales/ko.json";
import plLang from "../locales/pl.json";
import ruLang from "../locales/ru.json";
import enLang from "../locales/en.json";
import esLang from "../locales/es.json";
import urLang from "../locales/ur.json";
import yiLang from "../locales/yi.json";

export const defaultNS = "translations";

export const resources = {
  ar: { translations: arLang },
  bn: { translations: bnLang },
  "zh-CN": { translations: zhCnLang },
  en: { translations: enLang },
  es: { translations: esLang },
  fr: { translations: frLang },
  ht: { translations: htLang },
  it: { translations: itLang },
  ko: { translations: koLang },
  pl: { translations: plLang },
  ru: { translations: ruLang },
  ur: { translations: urLang },
  yi: { translations: yiLang },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    defaultNS,
    detection: {
      // order and from where user language should be detected
      order: [
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
      ],

      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: "myDomain",

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: "/", sameSite: "strict" },
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
