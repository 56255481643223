import styled from "styled-components";

export const StyledLocationCard = styled.li`
  min-width: var(--lib-min-card-width-rem);
  width: inherit;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);
  margin-bottom: 2px;

  display: grid;
  grid-template-columns: 1fr auto auto; //content, directions-btn, chevron
  grid-gap: 0.5rem;

  position: relative; //enables absolutely positioned StyledCardLink

  background-color: var(--lib-foreground);

  //identifies Directions button
  .lib-neutral-button {
    z-index: 2;
    align-self: center;
  }

  .chevron {
    align-self: center;
  }

  /*
    Technically these styles should belong to StyledCardLink::before:hover - 
    however changing the background-color of the link would obscure text with a lower z-index,
    and traversing upwards to StyledLocationCard is awkward.
  */
  &:hover {
    background-color: var(--lib-foreground-hover);
  }
  &:hover .chevron svg {
    color: var(--lib-primary-gray) !important;
  }
`;

// Left column of card
export const StyledContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--lib-default-spacing);

  // Centers StyledCardTitle vertically in minimal card (no optional loc details provided)
  margin-top: auto;
  margin-bottom: auto;

  // Additional gutter
  margin-right: 0.5rem;

  // Pass down
  font-size: var(--lib-text-size-0);
  color: var(--lib-secondary-gray);

  p a {
    //raise phone # above StyledCardLink
    position: relative;
    z-index: 2;

    //expand hitbox for link to avoid mis clicks on card bg
    padding: 0.5rem 0.5rem 0.5rem 0;

    font-size: inherit;

    :hover {
      color: var(--lib-secondary-blue);
      cursor: pointer;
    }
  }
`;

// Adds spacing between <ServiceOption>s
export const StyledServices = styled.p`
  > :not(:last-child) {
    padding-inline-end: var(--lib-default-spacing);
  }
`;

export const StyledCardTitle = styled.h3`
  color: var(--lib-primary-gray);
  font-size: var(--lib-text-size-1);
  font-weight: 700; //prevent extra tracking on Safari
  text-decoration: none;
`;

/* 
  Link to Details
  Clickable area expanded to cover full body of card,
  raised above non-interactive elements to prevent "holes"
*/
export const StyledCardLink = styled.a`
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: inherit;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`;

/*
  --span-color defined locally in inline styles
*/
export const StyledDetailSpan = styled.span`
  font-size: inherit;
  color: var(--span-color);
`;

export const StyledPhoneLink = styled.a`
  white-space: nowrap;
`;
