import Color from "@arcgis/core/Color";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import CustomContent from "@arcgis/core/popup/content/CustomContent";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import MapView from "@arcgis/core/views/MapView";
import EsriMap from "@arcgis/core/WebMap";
import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { getDirectionsUrl } from "../../utils";
import LocationCard from "../LocationCard";
import { LocationCardProps } from "../LocationCard/LocationCard.types";
import { getLocCardDetails } from "../LocationCard/LocationCardHelper";
import { StyledMap } from "./Map-styled";
import { MapProps, PopupUpdateEvent } from "./Map.types";
import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

const updatePopupHeight = (
  popup: __esri.Popup,
  popupHeightHandler: (x: PopupUpdateEvent) => void
) => {
  if (!popup.visible || popup.currentDockPosition !== "bottom-center") {
    popupHeightHandler({ newHeight: 0 });
  } else {
    let height;
    // unable to find a built-in way of being notified when popup is finished rendering
    // instead use a recursive function to check if the height has changed yet
    const checkHeight = () => {
      height = ((popup.container as HTMLElement).querySelector(`[role="dialog"]`) as HTMLElement)
        ?.offsetHeight;
      // there seems to be a middle state where the height is 40, so ignore anything under 100
      // changing to 50 since occasionally popup height gets stuck at 94px resulting in an infinite loop
      if (!height || height < 50) {
        setTimeout(checkHeight, 10);
      } else {
        popupHeightHandler({ newHeight: height });
      }
    };
    checkHeight();
  }
};

const createLocationCard = (args: LocationCardProps) => {
  return <LocationCard {...args}></LocationCard>;
};

const Map = ({
  itemId,
  initialCenter,
  initialScale,
  initialExtent,
  targetLayerUrl,
  locationsFeatureLayerInfo,
  onMapViewSet,
  onError: handleError,
  locationGraphicsLayerId,
  isDetails,
  onCardClick,
  onPopupUpdate,
  mainMap,
  updateGoogleTranslate,
  fieldLabels,
}: MapProps) => {
  const { t } = useTranslation("components", { i18n: instance });

  const mapDiv = useRef<HTMLDivElement>(null);

  const [mapView, setMapView] = useState<MapView | undefined>();
  const [isMapViewSet, setIsMapViewSet] = useState<boolean>(false);

  useEffect(() => {
    // Were not rerending based on the defined useEffect dependancies
    // This will only render one time.
    if (!isMapViewSet) {
      setIsMapViewSet(true);

      const map = new EsriMap({
        portalItem: {
          id: itemId,
        },
      });

      const highlightOptions = {
        color: new Color("#BA971A"),
        fillOpacity: 1.0,
        haloColor: new Color("#00FFFF"),
        haloOpacity: 1.0,
      };

      const view = new MapView({
        container: mapDiv.current ? mapDiv.current : undefined,
        map: map,
        scale: initialScale,
        center: initialCenter,
        extent: initialExtent,
        constraints: {
          minZoom: 9,
        },
        highlightOptions: highlightOptions,
      });

      setMapView(view);
      onMapViewSet(view);

      map.when().catch((err: unknown) => {
        console.warn(t("map.loadError", { error: err }));
        handleError(err);
      });

      view
        .when()
        .then(() => {
          // add a graphics layer to manage showing a custom graphic and symbol
          // for the currently selected location
          const locationGraphicsLayer = new GraphicsLayer({
            id: locationGraphicsLayerId,
          });
          view.map.layers.push(locationGraphicsLayer);
        })
        .catch((err: unknown) => {
          console.error(t("map.displayError", { error: err }));
          handleError(err);
        });

      // Popup configuration
      const facilityPopup = new CustomContent({
        outFields: ["*"],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        creator: (event: any) => {
          // event is type MessageEvent, but can't seemed to be replaced with type
          // __esri.PopupTemplateContentCreator
          const cardDetails = getLocCardDetails({
            loc: event.graphic,
            locationLayerInfo: locationsFeatureLayerInfo,
            locationLayer: event.graphic.layer,
            hideDistance: true,
            t,
            fieldLabels,
          });
          const args = {
            cardType: "popup",
            directionsLink: getDirectionsUrl(event.graphic, window.focalPoint),
            cardDetails,
            locObjId: event.graphic.attributes[locationsFeatureLayerInfo.objectIdFieldName],
            onClick: onCardClick,
            location: event.graphic,
          } as LocationCardProps;
          const component = createLocationCard(args);
          const element = document.createElement("div");
          element.id = "location_popup";
          const root = createRoot(element);
          root.render(component);
          // the timeout is needed because the first popup takes too long and will not get translated
          setTimeout(updateGoogleTranslate, 50);
          return element;
        },
      });

      const template = new PopupTemplate({
        content: [facilityPopup],
        overwriteActions: true,
        actions: [],
        lastEditInfoEnabled: false,
      });

      view.on("layerview-create", function (event: __esri.ViewLayerviewCreateEvent) {
        if (event.layer.type === "feature") {
          const fl = event.layer as FeatureLayer;
          // check if matches exactly or if it matches without the layer index (".../FeatureLayer" or ".../FeatureLayer/0")
          if (
            fl.url === targetLayerUrl ||
            (event.layer as FeatureLayer).url ===
              targetLayerUrl.substring(0, targetLayerUrl.lastIndexOf("/"))
          ) {
            if (isDetails) {
              fl.popupEnabled = false;
            } else {
              fl.popupTemplate = template;
            }
          }
        }
      });

      view.popup.watch("promises", async (promises) => {
        await Promise.all(promises);
        updatePopupHeight(view.popup, onPopupUpdate);
      });

      view.popup.watch("currentDockPosition", () => {
        updatePopupHeight(view.popup, onPopupUpdate);
      });
    }
  }, [
    handleError,
    initialCenter,
    initialScale,
    initialExtent,
    isDetails,
    isMapViewSet,
    itemId,
    locationGraphicsLayerId,
    locationsFeatureLayerInfo,
    mapView,
    onMapViewSet,
    targetLayerUrl,
    onCardClick,
    onPopupUpdate,
    mainMap,
    t,
  ]);

  return <StyledMap ref={mapDiv}></StyledMap>;
};

export default Map;
