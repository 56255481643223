import styled from "styled-components";
import { StyledTextCardProps } from "./TextCard.types";

export const StyledTextCard = styled.div`
  width: 100%;
  max-width: 25rem;
  height: auto;
  padding: var(--lib-default-spacing);
  box-sizing: border-box;

  background: rgba(255, 255, 255, ${({ opacity }: StyledTextCardProps) => opacity});
  --shadow: var(--lib-shadow) 0px 2px 4px;
  box-shadow: var(--shadow);
  color: var(--lib-secondary-gray);

  font-size: var(--lib-text-size-0);
  line-height: calc(var(--lib-text-size-0) * 1.5);

  h2 {
    color: var(--lib-primary-gray);
    font-size: var(--lib-text-size-2);
    margin-bottom: var(--lib-default-spacing);
  }

  ul {
    padding-inline-start: var(--lib-default-spacing);
  }
`;
