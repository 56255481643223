import React from "react";
import { FooterProps } from "./FooterBar.types";
import { StyledFooter, StyledLandingFooter, StyledDetailFooter } from "./FooterBar-styled";
import FaqButton from "../FaqButton";
import ShareButton from "../ShareButton";
import TranslationSelectGoogle from "../TranslationSelectGoogle/TranslationSelectGoogle";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";

import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";
import TranslationSelectI18n from "../TranslationSelectI18n";

const FooterBar = ({ footerType, onOpenDirections, faqUrl, onLocaleChange }: FooterProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  return (
    <I18nextProvider i18n={i18n}>
      <StyledFooter as={footerType === "landing" ? StyledLandingFooter : StyledDetailFooter}>
        {footerType === "landing" && !onLocaleChange && <TranslationSelectGoogle />}
        {footerType === "landing" && onLocaleChange && (
          <TranslationSelectI18n onChange={onLocaleChange} />
        )}
        {footerType === "details" && (
          <CalciteButton
            id="directions-btn"
            class="lib-neutral-button"
            onClick={onOpenDirections}
            appearance="outline"
            iconStart="road-sign"
            color="neutral"
          >
            {t("footer.directions")}
          </CalciteButton>
        )}
        {faqUrl && <FaqButton faqUrl={faqUrl}></FaqButton>}
        <ShareButton shareUrl={window.location.href}></ShareButton>
      </StyledFooter>
    </I18nextProvider>
  );
};

export default FooterBar;
