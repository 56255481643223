import React from "react";
import { StyledTextCard } from "./TextCard-styled";
import { TextCardProps } from "./TextCard.types";

const TextCard = ({ children, title, opacity }: TextCardProps) => {
  return (
    <StyledTextCard opacity={opacity}>
      <h2>{title}</h2>
      {children}
    </StyledTextCard>
  );
};

export default TextCard;
