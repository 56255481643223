import React, { useState } from "react";

import { I18nextProvider, useTranslation } from "react-i18next";
import instance from "../../utils/i18n";

import {
  StyledBooleanFilterBannerWrapper,
  StyledDefinitionRow,
} from "./BooleanFilterBanner-styled";

import { BooleanFilterBannerProps } from "./BooleanFilterBanner.types";

import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-button";

import { CalciteIcon, CalciteButton } from "@esri/calcite-components-react/";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPawSimple, faWheelchairMove } from "@fortawesome/pro-regular-svg-icons";

const BooleanFilterBanner = ({ booleanFilters, isMapView }: BooleanFilterBannerProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });

  const [showBanner, setShowBanner] = useState<boolean>(true);

  const usesFaIcon = (faIconProp: string) => {
    let faIcon;
    if (faIconProp === "paw") {
      faIcon = faPawSimple;
    }
    if (faIconProp === "wheelchair") {
      faIcon = faWheelchairMove;
    }
    return faIcon;
  };

  const dismiss = () => {
    setShowBanner(false);
  };

  return (
    <I18nextProvider i18n={i18n}>
      {showBanner && (
        <>
          <h3 className="visually-hidden">Location Icons</h3>
          <StyledBooleanFilterBannerWrapper isMapView={isMapView}>
            <div id="content">
              {booleanFilters.length > 0 &&
                booleanFilters.map((filter) => {
                  const faIcon = usesFaIcon(filter.icon_font_awesome);
                  return (
                    <>
                      {filter.icon_calcite && (
                        <StyledDefinitionRow key={filter.icon_calcite}>
                          <CalciteIcon icon={filter.icon_calcite}></CalciteIcon>
                          <p>{t(`booleanFilterBanner.${filter.icon_calcite}`)}</p>
                          <div className="legend-text">
                            <span>{t(`booleanFilterBanner.${filter.icon_calcite}SR`)}</span>
                          </div>
                        </StyledDefinitionRow>
                      )}
                      {faIcon && (
                        <StyledDefinitionRow key={filter.icon_font_awesome}>
                          <FontAwesomeIcon icon={faIcon}></FontAwesomeIcon>
                          <p>{t(`booleanFilterBanner.${filter.icon_font_awesome}`)}</p>
                          <div className="legend-text">
                            <span>{t(`booleanFilterBanner.${filter.icon_font_awesome}SR`)}</span>
                          </div>
                        </StyledDefinitionRow>
                      )}
                    </>
                  );
                })}
            </div>

            {booleanFilters.length > 0 && (
              <CalciteButton
                appearance="transparent"
                onClick={dismiss}
                label={t("booleanFilterBanner.dismissLabel")}
              >
                {t("booleanFilterBanner.dismiss")}
              </CalciteButton>
            )}
          </StyledBooleanFilterBannerWrapper>
        </>
      )}
    </I18nextProvider>
  );
};
export default BooleanFilterBanner;
