import React from "react";
import { FaqBtnProps } from "./FaqButton.types";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";

import "@esri/calcite-components/dist/components/calcite-button";
import { CalciteButton } from "@esri/calcite-components-react";

const onFaqClick = (faqUrl: string) => {
  window.open(faqUrl, "_blank");
};

const FaqButton = ({ faqUrl }: FaqBtnProps) => {
  const { t, i18n } = useTranslation("components", { i18n: instance });
  return (
    <I18nextProvider i18n={i18n}>
      <CalciteButton
        class="lib-neutral-button"
        id="faq-btn"
        onClick={() => onFaqClick(faqUrl)}
        appearance="outline"
        iconStart="question"
        color="neutral"
      >
        {t("faq")}
      </CalciteButton>
    </I18nextProvider>
  );
};

export default FaqButton;
