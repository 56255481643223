import React from "react";
import { StyledFieldSection, StyledSectionHeading, StyledServiceNote } from "../Details-styled";
import { SingleFieldSectionDetails } from "../Details.types";

const SingleFieldSection = ({ loc, fieldName, sectionTitle }: SingleFieldSectionDetails) => {
  return (
    <StyledFieldSection key={`singleField_${fieldName}`}>
      {sectionTitle && <StyledSectionHeading>{sectionTitle}</StyledSectionHeading>}
      <StyledServiceNote>{loc.attributes[fieldName]}</StyledServiceNote>
    </StyledFieldSection>
  );
};

export default SingleFieldSection;
