/*
  Similar pattern to StyledHeader
*/

import styled from "styled-components";

export const StyledFooter = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding: var(--lib-default-spacing);

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  gap: calc(var(--lib-default-spacing) / 2);

  --shadow: var(--lib-shadow) 0px -1px 2px;
  box-shadow: var(--shadow);
  background-color: var(--lib-foreground);
`;

export const StyledLandingFooter = styled(StyledFooter)`
  justify-content: center;
`;

export const StyledDetailFooter = styled(StyledFooter)`
  justify-content: space-between;
`;
