import i18next from "i18next";
import arLang from "../locales/ar.json";
import bnLang from "../locales/bn.json";
import enLang from "../locales/en.json";
import esLang from "../locales/es.json";
import frLang from "../locales/fr.json";
import htLang from "../locales/ht.json";
import itLang from "../locales/it.json";
import koLang from "../locales/ko.json";
import plLang from "../locales/pl.json";
import ruLang from "../locales/ru.json";
import urLang from "../locales/ur.json";
import yiLang from "../locales/yi.json";
import zhCnLang from "../locales/zh-CN.json";

export const defaultNS = "components";
export const resources = {
  ar: { components: arLang },
  bn: { components: bnLang },
  "zh-CN": { components: zhCnLang },
  en: { components: enLang },
  es: { components: esLang },
  fr: { components: frLang },
  ht: { components: htLang },
  it: { components: itLang },
  ko: { components: koLang },
  pl: { components: plLang },
  ru: { components: ruLang },
  ur: { components: urLang },
  yi: { components: yiLang },
} as const;

// create instance for component library and initialize it
// see https://luxiyalu.com/how-to-have-multiple-instances-of-i18next-for-component-library/
const instance = i18next.createInstance(
  {
    lng: "en",
    resources,
    fallbackLng: "en",
    defaultNS,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false }, // will not work without this
  },
  (err, t) => {
    // including callback will automatically initialize instance
    if (err) throw err;
  }
);
export const setLanguage = (language: string) => {
  instance.changeLanguage(language);
};
export default instance;
