import React from "react";
import { StyledImageCard } from "./ImageCard-styled";
import { ImageCardProps } from "./ImageCard.types";

const ImageCard = ({ children, imgSrcDefault, imgSrcNarrow }: ImageCardProps) => {
  return (
    <StyledImageCard
      imgSrcDefault={imgSrcDefault}
      imgSrcNarrow={imgSrcNarrow}
      data-testid="image-card"
    >
      {children}
    </StyledImageCard>
  );
};

export default ImageCard;
